<template>
  <div>
    <br />
    <div class="flex flex-row" style="align-items: center; justify-content: space-between">
      <div class="heading" style="color: #642c90">All Students</div>
      <img
        :src="require(`../assets/modalClose.svg`)"
        alt="close"
        class="pointer"
        style="margin-top: 8px; margin-right: -16px"
        @click="goBackToPrevious()"
      />
    </div>
    <hr />
    <br />
    <div class="search">
      <div class="search-container">
        <div class="search-bar">
          <input
            class="browser-default search-input"
            type="text"
            placeholder="Search Here"
            style="width: 190px; border-radius: 5px"
            v-model="searchValue"
            id="searchValue"
          />
          <div class="search-filters">
            <span class="select-fields">Select Course</span>&ensp;
            <select
              class="browser-default dropd"
              style="width: 190px; border-radius: 5px"
              @change="onCourseChange($event)"
            >
              <option value="" selected>Course</option>
              <option :value="data.CourseName" v-for="data in courses" :key="data.CourseName">
                {{ data.CourseName }}
              </option>
            </select>
          </div>
          <div class="search-filters">
            <span class="select-fields">Select Batch</span>&ensp;
            <select
              class="browser-default dropd"
              style="width: 190px; border-radius: 5px"
              @change="onBatchChange($event)"
            >
              <option value="" selected>Batch</option>
              <option :value="data.BatchName" v-for="data in UniqueBatches" :key="data.BatchName">
                {{ data.BatchName }}
              </option>
            </select>
          </div>
          <!-- <router-link to="AffiliationCreateUser"><button class="assignment-button">Add Student</button></router-link> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div>
        <table class="qtbl striped">
          <thead class="data-table-head">
            <tr>
              <th style="width: 12%">Student Name</th>
              <th style="width: 12%">Course</th>
              <th style="width: 12%">Batch</th>
              <th style="width: 12%">Join Date</th>
              <!-- <th>Action</th> -->
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
      <div v-for="(data, index) in filteredList" :key="data.UserId" :value="data.UserId">
        <table>
          <tbody>
            <tr class="table-body-row">
              <td style="font-weight: 700; width: 12%">
                <a @click="showBreakDownAnalysis(index, data)" :id="index">
                  &ensp; {{ data.Name }}</a
                >
              </td>
              <td style="width: 12%">{{ data.CourseName }}</td>
              <td style="width: 12%">{{ data.BatchName }}</td>
              <td style="width: 12%">{{ formatDate(data.CreatedOn) }}</td>
              <!-- <td><img src="../assets/Trash.svg" />&ensp;<img src="../assets/Edit_fill.svg" /></td> -->
            </tr>
          </tbody>
        </table>
        <div v-if="visibleOfBreakDownAnalysis === index">
          <div class="card-panel">
            <div class="row">
              <!-- <div class="col s2"><button  @click="openAssignment()" class="sassignbutton">Create Assignment</button></div> -->
              <div class="col s2">
                <button @click="effortAnalysis()" class="stusubmitbutton">Practice Analysis</button>
              </div>
              <div class="col s2">
                <button @click="testAnalysis()" class="stusubmitbutton">Test Analysis</button>
              </div>
              <!-- <div class="col s2"><button  @click="goAssignmentReport()" class="sreportbutton">Assignment Report</button></div> -->
              <!-- <div class="col s2"><button  @click="doubtReport()" class="stusubmitbutton">Doubt Report</button></div> -->
              <!-- <div class="col s2"><button  @click="resetPassword()" class="stusubmitbutton">Reset Password</button></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="data-table">
              <table class="qtbl striped">
                  <thead class="data-table-head">
                      <tr>
                          <th>Student Name</th>
                          <th>Course</th>
                          <th>Batch</th>
                          <th>Join Date</th>
                          <th>Action</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr class="table-body-row" v-for="(data,index) in filteredList" :key="data.UserId" :value="data.UserId">
                          <td style="font-weight: 700"> <a @click="showBreakDownAnalysis(index)" :id="index">
                            &ensp; {{ data.Name }}</a></td>
                          <td>{{ data.CourseName }}</td>
                          <td>{{ data.BatchName }}</td>
                          <td>{{ data.CreatedOn | formatDate }}</td>
                          <td><img src="../assets/Trash.svg" />&ensp;<img src="../assets/Edit_fill.svg" /></td>
                      </tr>
                  </tbody>
              </table>
      </div> -->
  </div>
</template>

<script>
import moment from "moment";
// import Vue from "vue";
import Mobileapi from "../Mobileapi";

// Vue.filter("formatDate", (value) => {
//   if (value) {
//     return moment(String(value)).format("DD/MM/YYYY, HH:mm a");
//   }

//   return value;
// });
export default {
  data() {
    return {
      visibleOfBreakDownAnalysis: -1,
      studentlist: [],
      searchValue: "",
      selectedcourse: "",
      selectedbatch: "",
      courses: [],
      batches: [],
      userId: null,
      userName: "",
    };
  },
  mounted() {
    const user = this.$store.getters.user;
    Mobileapi.getAffiliationStudentList(user.AffiliationId, (response) => {
      this.studentlist = response.data;
      const uniqueObjArray1 = [
        ...new Set(this.studentlist.map((item) => ({ CourseName: item.CourseName }))),
      ];
      this.courses = [...new Map(uniqueObjArray1.map((item) => [item.CourseName, item])).values()];
      const uniqueObjArray2 = [
        ...new Set(this.studentlist.map((item) => ({ BatchName: item.BatchName }))),
      ];
      this.batches = [...new Map(uniqueObjArray2.map((item) => [item.BatchName, item])).values()];
    });
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY, HH:mm a");
      }

      return value;
    },
    onCourseChange(event) {
      this.selectedcourse = event.target.value;
      this.selectedbatch = "";
      this.filteredList(this.selectedcourse);
    },
    onBatchChange(event) {
      this.selectedbatch = event.target.value;
      this.$set(this.filteredList(this.selectedbatch));
      this.UniqueBatches(this.selectedbatch);
    },
    showBreakDownAnalysis(index, data) {
      if (this.visibleOfBreakDownAnalysis === index) {
        this.visibleOfBreakDownAnalysis = -1;
      } else {
        this.visibleOfBreakDownAnalysis = index;
        this.userId = data.UserId;
        this.userName = data.Name;
      }
    },
    openAssignment() {
      this.$router.push({ name: "CreateAssignment" });
    },
    effortAnalysis() {
      this.$router.push({
        name: "EffortAnalysisNew",
        params: {
          StuAffDashUserId: Number(this.userId),
          StuAffDashUserName: this.userName,
          Source: Number(2),
        },
      });
    },
    testAnalysis() {
      this.$router.push({
        name: "ActionInstituteTestNew",
        params: {
          StuAffDashUserId: Number(this.userId),
          StuAffDashUserName: this.userName,
          Source: Number(2),
        },
      });
    },
    goAssignmentReport() {
      this.$router.push({ name: "AssignmentReport" });
    },
    doubtReport() {
      this.$router.push({ name: "StudentDoubtReport" });
    },
    resetPassword() {},
    goBackToPrevious() {
      this.$store.dispatch("showLoader", true);
      this.$router.push({ name: "AffiliationDashboard" });
      this.$store.dispatch("showLoader", false);
    },
  },
  computed: {
    filteredList() {
      let templist = this.studentlist;
      if (this.searchValue !== "" && this.searchValue) {
        templist = templist.filter((item) =>
          item.Name.toLowerCase().includes(this.searchValue.toLowerCase()),
        );
      }
      if (this.selectedcourse !== "" && this.selectedcourse) {
        templist = templist.filter((item) =>
          item.CourseName.toLowerCase().includes(this.selectedcourse.toLowerCase()),
        );
      }
      if (this.selectedcourse === "") {
        templist = this.studentlist;
      }
      if (this.selectedbatch !== "" && this.selectedbatch) {
        templist = templist.filter((item) =>
          item.BatchName.toLowerCase().includes(this.selectedbatch.toLowerCase()),
        );
      }
      return templist;
    },
    UniqueBatches() {
      let templist1 = this.studentlist;
      if (this.selectedcourse !== "" && this.selectedcourse) {
        templist1 = templist1.filter((item) =>
          item.CourseName.toLowerCase().includes(this.selectedcourse.toLowerCase()),
        );
      }
      const uniqueObjArray2 = [
        ...new Set(templist1.map((item) => ({ BatchName: item.BatchName }))),
      ];
      const batch = [...new Map(uniqueObjArray2.map((item) => [item.BatchName, item])).values()];
      return batch;
    },
  },
};
</script>

<style scoped>
.heading {
  margin-top: 20px;
  /* font-family: Open Sans;
  font-style: normal; */
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  color: #333333;
}

.search-bar {
  display: flex;
  justify-content: space-between;
}

.search-container {
  width: 100%;
  padding: 15px;
  height: 70px;
  background: #ffffff;
}

.search {
  display: flex;
  justify-content: space-between;
}

.search-filters {
  display: flex;
}

.batch-button {
  background: linear-gradient(to right, #ff9421, #fbad26);
  width: 136px;
  height: 36px;
  border-radius: 5px;
}

.assignment-button {
  background: #642c90;
  width: 167px;
  height: 36px;
  border-radius: 5px;
  color: #ffffff;
}

.browser-default {
  height: 36px;
  width: 268px;
}

.data-table-head {
  border-radius: 5px;
  background: #642c90;
  color: white;
  font: Open Sans;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 19.07px;
}

.table-select {
  width: 20px;
  height: 20px;
}

.table-body-row {
  border-radius: 5px;
  background: white;
}

table.qtbl {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
}

table.qtbl thead tr {
  background-color: #6a2f85;
  color: #fff;
}

table.qtbl thead tr td {
  border-radius: 5px;
}

table.qtbl thead tr td:first-child,
table.qtbl tbody tr td:first-child {
  border-radius: 5px 0 0 5px;
}

table.qtbl tbody tr {
  cursor: pointer;
  background-color: #fff;
}

.select-fields {
  /* font-family: Open Sans;
  font-style: normal; */
  margin-top: 5px;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.563rem;
  /* identical to box height */
  color: #333333;
}

@media screen and (max-width: 850px) {
  .search-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .search-container {
    width: 100%;
    padding: 15px;
    height: 150px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
  }

  .search-filters {
    display: flex;
    justify-content: space-around;
  }

  .search-input {
    display: none;
  }
}

.stusubmitbutton {
  background: #643788;
  color: white;
  border: white;
  border-radius: 5px;
  font-size: 14px;
  width: 130px;
  height: 38px;
  cursor: pointer;
}

.sassignbutton {
  background: #643788;
  color: white;
  border: white;
  border-radius: 5px;
  font-size: 14px;
  width: 145px;
  height: 38px;
  cursor: pointer;
}

.sreportbutton {
  background: #643788;
  color: white;
  border: white;
  border-radius: 5px;
  font-size: 14px;
  width: 145px;
  height: 38px;
  cursor: pointer;
}
</style>
