<template>
  <div class="batch-screen">
    <br />
    <router-link class="heading" to="AffiliationDashboard">Dashboard / </router-link
    ><span class="heading" style="color: #642c90">Batches</span>
    <hr />
    <br />
    <div class="search">
      <div class="search-container">
        <div class="search-bar">
          <input
            type="text"
            placeholder="Search Here"
            style="width: 170px; height: 36px"
            v-model="searchValue"
          />
          <select
            class="browser-default dropd"
            style="width: 170px; height: 36px"
            @change="onCourseChange($event)"
          >
            <option value="" disabled selected>Course</option>
            <option :value="batch.CourseName" v-for="batch in batches" :key="batch.CourseName">
              {{ batch.CourseName }}
            </option>
          </select>
        </div>
        <div v-if="User.role === 'Affiliation Head'" class="search-button">
          <button v-if="clickCount === 0" class="assignment-button" @click="showInActiveBatches()">
            Inactive Batches
          </button>
          <button v-if="clickCount === 1" class="assignment-button" @click="showActiveBatches()">
            Active Batches
          </button>
          <button class="batch-button" @click="OpenModal()">Add Batch</button>
          <!-- <button class="assignment-button">Add Assignment</button> -->
        </div>
      </div>
    </div>
    <div class="data-table">
      <table class="qtbl striped">
        <thead class="data-table-head">
          <tr>
            <th>Batch Name</th>
            <th>Total Student</th>
            <th>Course</th>
            <th>Class</th>
            <th>Academic Session</th>
            <th>Expiry Date</th>
            <th>Is Active</th>
            <th v-if="User.role === 'Affiliation Head' && clickCount === 0">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-body-row" v-for="data in filteredList" :key="data.Id" :value="data.Id">
            <td style="font-weight: 700">&ensp; {{ data.Name }}</td>
            <td>{{ data.TotalStudents }}</td>
            <td>{{ data.CourseName }}</td>
            <td>{{ data.ClassName }}</td>
            <td>{{ data.AcademicSession }}</td>
            <td>{{ formatDate(data.BatchExpiryDate) }}</td>
            <td>
              <i class="material-icons" style="color: #642c90" v-if="data.IsActive === true"
                >check_box</i
              >
              <i class="material-icons" style="color: lightgray" v-if="data.IsActive === false"
                >check_box</i
              >
            </td>
            <td v-if="User.role === 'Affiliation Head' && clickCount === 0">
              <img :src="require(`../assets/Trash.svg`)" @click="deleteModal(data.Id)" />&ensp;<img
                :src="require(`../assets/Edit_fill.svg`)"
                @click="editBatch(data.Id)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Modal
      :show="showCampaignModal"
      @close="showCampaignModal = false"
      :showHeader="true"
      :showCloseBtn="true"
    >
      <template v-slot:body class="modal-body">
        <div class="row nmb">
          <div class="col s9">
            <h5>
              <strong style="color: #642c90">{{ this.batchModalTitle }}</strong>
            </h5>
            <div class="divider" style="width: 750px"></div>
          </div>
        </div>
        <div class="row" style="margin-top: 10px">
          <div class="col s12 m6">
            <span class="add-title">Affiliation:</span>
            <input class="add-input" type="text" v-model="this.affiliationName" disabled />
          </div>
          <div class="col s12 m6">
            <span class="add-title">Batch Name:</span>
            <input type="text" v-model="batchName" />
            <div v-if="NameValidation" style="color: red">Enter the Name For Batch.</div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m6">
            <span class="add-title">Batch Size:</span>
            <input type="number" v-model="batchsize" />
          </div>
          <div class="col s12 m6">
            <span class="add-title">City:</span>
            <select
              class="browser-default dropd"
              @change="onBatchCityChange($event)"
              v-model="batchcity"
            >
              <option value="" disabled selected>-- Select City--</option>
              <option :value="data.cityId" v-for="data in city" :key="data.cityId">
                {{ data.cityName }}
              </option>
            </select>
            <div v-if="CityValidation" style="color: red">Please Select City.</div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m6">
            <span class="add-title">Expiry Date:</span>
            <input type="date" :min="this.startDate" v-model="expirydate" />
          </div>
          <div class="col s12 m6">
            <span class="add-title">Batch Expiry Date:</span>
            <input type="date" :min="this.startDate" v-model="batchexpirydate" />
            <div v-if="BatchExpiryValidation" style="color: red">Please Select Expiry Date.</div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m6">
            <span class="add-title">Course:</span>
            <select
              class="browser-default dropd"
              @change="onBatchCourseChange($event)"
              v-model="batchcourse"
            >
              <option value="" disabled selected>-- Select Course --</option>
              <option
                :value="course.courseId"
                v-for="course in courses"
                :key="course.affiliationCourseId"
              >
                {{ course.courseName }}
              </option>
            </select>
            <div v-if="CourseValidation" style="color: red">Please choose a Course.</div>
          </div>
          <div class="col s12 m6">
            <span class="add-title">Subject:</span>
            <div>
              <VueMultiselect
                v-model="AffiliationSubject"
                :options="listOfSubjectsForSelectedCourse"
                placeholder="Select one"
                @update:model-value="updateSelectedSubject"
                label="subjectName"
                track-by="subjectId"
                :multiple="true"
                :limit="1"
                :close-on-select="false"
                @tag="SubjectTag"
              >
                <template v-slot:beforeList style="margin-top: 5px">
                  <div class="row" style="margin-top: 5px">
                    <a
                      class="multiselectBtn waves-effect waves-light btn"
                      @click="selectSubjectNone"
                      ><span style="display: flex; justify-content: center">None</span></a
                    >&nbsp;
                    <a class="multiselectBtn waves-effect waves-light btn" @click="selectSubjectAll"
                      ><span>All </span></a
                    >
                  </div>
                </template>
              </VueMultiselect>
            </div>
            <div v-if="SubjectValidation" style="color: red">Please Choose Subject.</div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m6">
            <span class="add-title">Academic Session:</span>
            <select class="browser-default dropd" v-model="selectedAcademicSessionId">
              <option value="" disabled selected>-- Select Session --</option>
              <option
                :value="course.AcademicSessionId"
                v-for="course in academicSessionList"
                :key="course.AcademicSessionId"
              >
                {{ course.Description }}
              </option>
            </select>
            <div v-if="SessionValidation" style="color: red">
              Please choose an academic session.
            </div>
          </div>
          <div class="col s12 m6">
            <span class="add-title">Class:</span>
            <select class="browser-default dropd" v-model="selectedClassId">
              <option value="" disabled selected>-- Select Class --</option>
              <option :value="course.id" v-for="course in classList" :key="course.id">
                {{ course.displayName }}
              </option>
            </select>
            <div v-if="ClassValidation" style="color: red">Please choose a class.</div>
          </div>
          <div class="col s12 m4" style="margin-top: 20px">
            <strong>
              <label>
                <input type="checkbox" class="filled-in" id="IsActive" v-model="isActive" />
                <span class="add-title" style="color: black; font-weight: 600">Is Active</span>
              </label></strong
            >
          </div>
        </div>
        <div class="row" v-if="this.isEditMode == false">
          <div style="display: flex; justify-content: center">
            <button class="add-button" @click="AddBatch()">{{ this.batchModalButtonText }}</button>
          </div>
        </div>
        <div class="row" v-if="this.isEditMode == true">
          <div style="display: flex; justify-content: center">
            <button class="add-button" @click="EditBatch()">{{ this.batchModalButtonText }}</button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal
      :show="showDeleteBatchModal"
      @close="showDeleteBatchModal = false"
      :showHeader="false"
      :showCloseBtn="true"
      style="overflow-y: none !important"
    >
      <template v-slot:body class="cancel-modal">
        <div class="row">
          <span class="heading" style="color: #642c90">Delete Batch</span>
        </div>
        <div class="row">
          <span class="heading">Do you want to delete the Batch ?</span>
        </div>
        <div class="button-row" style="margin-top: 50px !important">
          <div class="col">
            <button class="cancel-button" @click="showDeleteBatchModal = false">No</button
            >&ensp;&ensp;
            <button @click="deleteBatch(batchId)" class="delete-button">Yes</button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import VueMultiselect from "vue-multiselect";
import Api from "../Api";
import Mobileapi from "../Mobileapi";
import Modal from "../components/Modal.vue";

export default {
  data() {
    return {
      clickCount: 0,
      batchlist: [],
      affiliationlist: [],
      courses: [],
      AffiliationSubject: [],
      city: [],
      listOfSubjectsForSelectedCourse: [],
      affiliationName: "",
      searchValue: "",
      selectedcourse: "",
      batches: "",
      batchName: "",
      batchcity: "",
      batchsize: "",
      batchexpirydate: "",
      batchcourse: "",
      expirydate: "",
      selectedCity: "",
      showCampaignModal: false,
      showDeleteModal: false,
      selectedAffiliation: "",
      selectBatchCourse: "",
      CityValidation: false,
      NameValidation: false,
      CourseValidation: false,
      SubjectValidation: false,
      BatchExpiryValidation: false,
      academicSessionList: [],
      selectedAcademicSessionId: "",
      SessionValidation: false,
      selectedClassId: "",
      classList: [],
      ClassValidation: false,
      User: [],
      batchModalTitle: "",
      batchModalButtonText: "",
      isEditMode: false,
      selectedBatchId: "",
      affiliationCourseId: 0,
      isActive: "",
      batchActive: true,
      startDate: "",
      showDeleteBatchModal: false,
      batchId: null,
    };
  },
  activated() {
    this.startDate = moment().add(15, "minutes").format("YYYY-MM-DD");
    const user = this.$store.getters.user;
    this.User = user;
    this.selectedCity = "";
    this.batchName = "";
    this.batchsize = "";
    this.batchexpirydate = "";
    this.expirydate = "";
    this.subjectId = "";
    this.batchcourse = "";
    this.selectedAcademicSessionId = "";
    this.selectedClassId = "";
    this.selectedSubjects = [];
    Api.getCourses({}, (response) => {
      this.courses = response.data;
    });
    Api.getCities({}, (resp) => {
      this.city = resp.data;
    });
    Mobileapi.getallAcademicSessions({}, (resp) => {
      this.academicSessionList = resp.data;
    });
    Mobileapi.getAffiliationBatchList(user.AffiliationId, { isActive: 1 }, (response) => {
      this.batchlist = response.data;
      const uniqueObjArray1 = [
        ...new Set(this.batchlist.map((item) => ({ CourseName: item.CourseName }))),
      ];
      this.batches = [...new Map(uniqueObjArray1.map((item) => [item.CourseName, item])).values()];
    });
    Mobileapi.getall({}, (response) => {
      this.affiliationlist = response.data;
    });
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY, HH:mm a");
      }
      return value;
    },
    deleteModal(batchId) {
      this.batchId = Number(batchId);
      this.showDeleteBatchModal = true;
    },
    showInActiveBatches() {
      this.clickCount = 1;
      const user = this.$store.getters.user;
      Mobileapi.getAffiliationBatchList(user.AffiliationId, { isActive: 0 }, (response) => {
        this.batchlist = response.data;
        const uniqueObjArray1 = [
          ...new Set(this.batchlist.map((item) => ({ CourseName: item.CourseName }))),
        ];
        this.batches = [
          ...new Map(uniqueObjArray1.map((item) => [item.CourseName, item])).values(),
        ];
      });
    },
    showActiveBatches() {
      this.clickCount = 0;
      const user = this.$store.getters.user;
      Mobileapi.getAffiliationBatchList(user.AffiliationId, { isActive: 1 }, (response) => {
        this.batchlist = response.data;
        const uniqueObjArray1 = [
          ...new Set(this.batchlist.map((item) => ({ CourseName: item.CourseName }))),
        ];
        this.batches = [
          ...new Map(uniqueObjArray1.map((item) => [item.CourseName, item])).values(),
        ];
      });
    },
    onCourseChange(event) {
      this.selectedcourse = event.target.value;
      this.filteredList(this.selectedcourse);
    },
    onBatchCourseChange(event) {
      const course = this.courses.filter((a) => a.courseId === Number(event.target.value))[0];
      this.selectBatchCourse = course.courseId;
      this.affiliationCourseId = course.affiliationCourseId;
      this.listOfSubjectsForSelectedCourse = course.subjectList;
      Mobileapi.getCourseClass(this.batchcourse, (resp) => {
        this.classList = resp.data;
      });
    },
    onBatchCityChange(event) {
      this.batchcity = event.target.value;
    },
    SubjectTag(newTag) {
      const tag = {
        SubjectName: newTag,
        SubjectId: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.listOfSubjectsForSelectedCourse.push(tag);
      this.AffiliationSubject.push(tag);
    },
    selectSubjectNone() {
      this.AffiliationSubject = [];
    },
    selectSubjectAll() {
      this.AffiliationSubject = this.listOfSubjectsForSelectedCourse;
      const subject = this.AffiliationSubject.map((a) => a.affiliationSubjectId);
      this.subjectId = String(subject);
      // console.log("subjects", this.subjectId);
    },
    updateSelectedSubject(value) {
      if (this.AffiliationSubject.length >= 0) {
        this.selectedSubjects = [];
      }
      value.forEach((resource) => {
        this.selectedSubjects.push(resource);
      });
      const subject = this.selectedSubjects.map((a) => a.affiliationSubjectId);
      this.subjectId = String(subject);
      // console.log(subject);
      this.SubjectValidation = false;
      // console.log(this.subjectId);
    },
    editBatch(batchId) {
      this.OpenModalInEditMode(batchId);
    },
    OpenModalInEditMode(batchId) {
      this.selectedBatchId = batchId;
      this.isEditMode = true;
      this.batchModalTitle = "Edit Batch";
      this.batchModalButtonText = "Edit Batch";
      this.showCampaignModal = true;
      const user = this.$store.getters.user;
      this.selectedAffiliation = this.affiliationlist.filter(
        (a) => a.Id === Number(user.AffiliationId),
      );
      this.affiliationName = this.selectedAffiliation[0].Name;
      let batchData;
      let batchSubjectData = [];
      this.AffiliationSubject = [];
      Mobileapi.getBatchDetails(batchId, (response1) => {
        batchData = response1.data[0][0];
        batchSubjectData = response1.data[1];
        this.batchName = batchData.Name;
        this.batchsize = batchData.BatchSize;
        this.subjectId = "";
        this.affiliationCourseId = batchData.AffiliationCourseId;
        this.batchcourse = batchData.CourseId;
        this.selectedAcademicSessionId = batchData.AcademicSessionId;
        this.selectBatchCourse = this.selectedcourse;
        for (let i = 0; i < this.courses.length; i += 1) {
          if (this.courses[i].courseId === Number(this.batchcourse)) {
            this.listOfSubjectsForSelectedCourse = this.courses[i].subjectList;
            this.AffiliationCourseId = Number(this.courses[i].affiliationCourseId);
            break;
          }
        }
        batchSubjectData.forEach((value) => {
          if (value.IsActive === true) {
            this.AffiliationSubject.push(
              this.listOfSubjectsForSelectedCourse.filter(
                (a) => Number(a.affiliationSubjectId) === Number(value.AffiliationSubjectId),
              )[0],
            );
          }
          this.updateSelectedSubject(this.AffiliationSubject);
        });
        Mobileapi.getCourseClass(this.batchcourse, (resp) => {
          this.classList = resp.data;
        });
        if (batchData.BatchExpiryDate != null) {
          this.batchexpirydate = moment(String(batchData.BatchExpiryDate)).format("YYYY-MM-DD");
        }
        if (batchData.StudentExpiryDate != null) {
          this.expirydate = moment(String(batchData.StudentExpiryDate)).format("YYYY-MM-DD");
        }
        this.batchcity = Number(batchData.CityId);
        this.selectedClassId = batchData.ClassId;
        this.isActive = batchData.IsActive;
      });
    },
    OpenModal() {
      this.isEditMode = false;
      this.selectedCity = "";
      this.batchName = "";
      this.batchsize = "";
      this.batchexpirydate = "";
      this.expirydate = "";
      this.subjectId = "";
      this.batchcourse = "";
      this.selectedAcademicSessionId = "";
      this.selectedClassId = "";
      this.selectedSubjects = [];
      this.AffiliationSubject = [];
      this.batchcity = "";
      this.batchModalTitle = "Add Batch";
      this.batchModalButtonText = "Add Batch";
      this.showCampaignModal = true;
      this.isActive = true;
      const user = this.$store.getters.user;
      this.selectedAffiliation = this.affiliationlist.filter(
        (a) => a.Id === Number(user.AffiliationId),
      );
      this.affiliationName = this.selectedAffiliation[0].Name;
    },
    AddBatch() {
      const user = this.$store.getters.user;
      if (this.batchName === "" || this.batchName.length <= 0) {
        this.NameValidation = true;
        return;
      }
      if (this.batchcity === null || this.batchcity <= 0) {
        this.CityValidation = true;
        return;
      }
      if (this.batchexpirydate === null || this.batchexpirydate <= 0) {
        this.BatchExpiryValidation = true;
        return;
      }
      if (this.batchcourse === null || this.batchcourse <= 0) {
        this.CourseValidation = true;
        return;
      }
      if (this.AffiliationSubject === null || this.AffiliationSubject <= 0) {
        this.SubjectValidation = true;
        return;
      }
      if (this.selectedAcademicSessionId === null || this.selectedAcademicSessionId <= 0) {
        this.SessionValidation = true;
        return;
      }
      if (this.selectedClassId === null || this.selectedClassId <= 0) {
        this.ClassValidation = true;
        return;
      }
      this.SessionValidation = false;
      const data = {
        affiliationid: Number(user.AffiliationId),
        code: Math.random().toString(36).slice(2),
        city: this.batchcity,
        name: this.batchName,
        batchSize: this.batchsize,
        batchExpiryDate: this.batchexpirydate,
        expiryDate: this.expirydate,
        affiliationSubjectsId: this.subjectId,
        affiliationCourseId: this.affiliationCourseId,
        academicSessionId: this.selectedAcademicSessionId,
        classId: this.selectedClassId,
        isActive: this.isActive,
      };
      Mobileapi.addbatch(data, (response) => {
        if (response.responseCode === 201) {
          window.M.toast({
            html: "Batch Added Successfully",
            classes: "rounded success",
          });
          this.showCampaignModal = false;
          this.batchcity = "";
          this.batchName = "";
          this.batchsize = "";
          this.batchexpirydate = "";
          this.expirydate = "";
          this.subjectId = "";
          this.batchcourse = "";
          this.selectedAcademicSessionId = "";
          this.selectedClassId = "";
          this.selectedSubjects = [];
          this.AffiliationSubject = [];
        } else if (response.responseCode === 200) {
          window.M.toast({
            html: "Duplicate Batch exists",
            classes: "rounded error",
          });
          this.showCampaignModal = true;
          Mobileapi.getAffiliationBatchList(user.AffiliationId, (response1) => {
            this.batchlist = response1.data;
            const uniqueObjArray1 = [
              ...new Set(this.batchlist.map((item) => ({ CourseName: item.CourseName }))),
            ];
            this.batches = [
              ...new Map(uniqueObjArray1.map((item) => [item.CourseName, item])).values(),
            ];
          });
        }
      });
    },
    EditBatch() {
      const user = this.$store.getters.user;
      if (this.batchName === "" || this.batchName.length <= 0) {
        this.NameValidation = true;
        return;
      }
      if (this.batchcity === null || this.batchcity <= 0) {
        this.CityValidation = true;
        return;
      }
      if (this.batchexpirydate === null || this.batchexpirydate <= 0) {
        this.BatchExpiryValidation = true;
        return;
      }
      if (this.batchcourse === null || this.batchcourse <= 0) {
        this.CourseValidation = true;
        return;
      }
      if (this.AffiliationSubject === null || this.AffiliationSubject <= 0) {
        this.SubjectValidation = true;
        return;
      }
      if (this.selectedAcademicSessionId === null || this.selectedAcademicSessionId <= 0) {
        this.SessionValidation = true;
        return;
      }
      if (this.selectedClassId === null || this.selectedClassId <= 0) {
        this.ClassValidation = true;
        return;
      }
      this.SessionValidation = false;
      const data = {
        batchId: Number(this.selectedBatchId),
        affiliationid: Number(user.AffiliationId),
        code: Math.random().toString(36).slice(2),
        city: this.batchcity,
        name: this.batchName,
        batchSize: this.batchsize,
        batchExpiryDate: this.batchexpirydate,
        expiryDate: this.expirydate,
        affiliationSubjectsId: this.subjectId,
        affiliationCourseId: this.affiliationCourseId,
        academicSessionId: this.selectedAcademicSessionId,
        classId: this.selectedClassId,
        isActive: this.isActive,
      };
      Mobileapi.editBatch(data, (response) => {
        if (response.responseCode === 201) {
          window.M.toast({
            html: "Batch updated Successfully",
            classes: "rounded success",
          });
        } else if (response.responseCode === 200) {
          window.M.toast({
            html: "Duplicate Batch exists",
            classes: "rounded error",
          });
          Mobileapi.getAffiliationBatchList(user.AffiliationId, (response1) => {
            this.batchlist = response1.data;
            const uniqueObjArray1 = [
              ...new Set(this.batchlist.map((item) => ({ CourseName: item.CourseName }))),
            ];
            this.batches = [
              ...new Map(uniqueObjArray1.map((item) => [item.CourseName, item])).values(),
            ];
          });
        }
      });
      this.showCampaignModal = false;
      this.batchcity = "";
      this.batchName = "";
      this.batchsize = "";
      this.batchexpirydate = "";
      this.expirydate = "";
      this.subjectId = "";
      this.batchcourse = "";
      this.selectedAcademicSessionId = "";
      this.selectedClassId = "";
      this.selectedSubjects = [];
      this.AffiliationSubject = [];
    },
    deleteBatch(BatchId) {
      this.batchId = Number(BatchId);
      this.$store.dispatch("showLoader", true);
      Mobileapi.deleteBatch({ BatchId: this.batchId }, (response) => {
        this.batchdelete = response.data;
        if (response.responseCode === 200) {
          window.M.toast({
            html: "Batch Deleted SuccessFully",
            classes: "rounded success",
          });
        }
        this.$store.dispatch("showLoader", false);
        this.showDeleteModal = false;
        this.$router.go();
      });
      Mobileapi.getAffiliationBatchList(this.User.AffiliationId, (response1) => {
        this.batchlist = response1.data;
        const uniqueObjArray1 = [
          ...new Set(this.batchlist.map((item) => ({ CourseName: item.CourseName }))),
        ];
        this.batches = [
          ...new Map(uniqueObjArray1.map((item) => [item.CourseName, item])).values(),
        ];
      });
      this.showDeleteBatchModal = false;
    },
  },
  computed: {
    filteredList() {
      let templist = this.batchlist;
      if (this.searchValue !== "" && this.searchValue) {
        templist = templist.filter(
          (item) => item.Name.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0,
        );
      } else if (this.selectedcourse !== "" && this.selectedcourse) {
        templist = templist.filter((item) =>
          item.CourseName.toLowerCase().includes(this.selectedcourse.toLowerCase()),
        );
      }
      return templist;
    },
  },
  components: {
    Modal,
    VueMultiselect,
  },
};
</script>
<style scoped>
.batch-screen {
  height: 100vh;
  width: 100%;
}

.heading {
  margin-top: 20px;
  /* font-family: Open Sans;
    font-style: normal; */
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  color: #333333;
}

.search {
  display: flex;
  justify-content: space-between;
}

.search-container {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  height: 56px;
  background: #ffffff;
}

.search-bar {
  display: flex;
  width: 400px;
  justify-content: space-between;
}

.search-button {
  display: flex;
  width: 350px;
  justify-content: space-between;
}

.batch-button {
  background: linear-gradient(to right, #ff9421, #fbad26);
  width: 136px;
  height: 36px;
  border-radius: 5px;
  color: #ffffff;
  border: none;
}

.assignment-button {
  background: #6a2f85;
  width: 167px;
  height: 36px;
  border-radius: 5px;
  color: #ffffff;
  border: none;
}

.data-table-head {
  border-radius: 15px;
  background: #642c90;
  color: white;
  font: Open Sans;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 19.07px;
}

table.qtbl {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
}

table.qtbl thead tr {
  background-color: #6a2f85;
  color: #fff;
}

table.qtbl thead tr td {
  border-radius: 5px;
}

table.qtbl thead tr td:first-child,
table.qtbl tbody tr td:first-child {
  border-radius: 5px 0 0 5px;
}

table.qtbl tbody tr {
  cursor: pointer;
  background-color: #fff;
}

.modal-body {
  height: 700px;
  overflow-y: hidden;
}

.add-input {
  border: 1px solid #ccc;
}

.add-title {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
}

.add-button {
  margin-top: 30px;
  background: linear-gradient(to right, #ff9421, #fbad26);
  width: 90%;
  height: 36px;
  border-radius: 5px;
  color: #ffffff;
}

.multiselectBtn {
  width: 90px;
  height: 35px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}

.browser-default {
  border-radius: 5px;
  height: 43px;
  border-color: #ccc;
}
.cancel-modal {
  height: 150px !important;
  width: 500px !important;
  overflow-x: none !important;
  overflow-y: none !important;
}
.cancel-button {
  height: 25px;
  width: 95px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
  background-color: #ffffff;
  color: black;
  border: none;
}

.delete-button {
  background-color: orange;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  height: 25px;
  width: 95px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
